@font-face {
  font-family: "Noto Color Emoji";
  src: url(/fonts/NotoColorEmoji.ttf);
}

$orange: #ff4a01;

$font-family-sans-serif: "Calibri", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/@contagt/bootstrap-select/sass/bootstrap-select.scss";

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: black;
}

html,
body {
  height: 100%;
}

.modal-custom-fullscreen {
  max-width: 95%;
  height: 95%;
  margin: 15px auto;
  .modal-content {
    height: 100%;
  }
}

.table-custom-double-striped {
  tbody tr:nth-of-type(4n + 3),
  tbody tr:nth-of-type(4n) {
    background-color: rgba(0, 0, 0, 0);
  }
  tbody tr:nth-of-type(4n + 1),
  tbody tr:nth-of-type(4n + 2) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.table-custom-extra-padding {
  td {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
}

.checkbox-custom {
  display: none;
}
.checkbox-custom ~ span:before {
  content: "✖ ";
}
.checkbox-custom:checked ~ span:before {
  content: "✔ ";
}

.bg-orange {
  background: $orange;
}

.bg-custom-1 {
  background-color: #0062cc;
  background-image: url("/images/bg-custom-1.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.bg-custom-2 {
  background-color: black;
  color: white;
  background-image: url("/images/bg-custom-2.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.bg-custom-3 {
  background-color: #ffffff;
  background-image: url("/images/bg-custom-3.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.custom-modal-header {
  justify-content: flex-end;
  .custom-modal-header-link {
    cursor: pointer;
    font-size: 1.5rem;
    opacity: 0.5;
    line-height: 1;
    text-decoration: none;
    &:hover {
      opacity: 1;
    }
  }
  .close {
    padding: 0;
    margin: 0;
  }
}

.custom-user-text {
  overflow-wrap: break-word;
}

.inside-iframe {
  .not-iframe {
    display: none;
  }
}

.card-custom-message {
  flex: 0 1 auto;
  max-width: 80%;
  min-width: 50%;
};

.card-custom-system {
  background: #e1f3fb;
}

.card-custom-outgoing {
  background: #dcf8c6;
}

.nobr {
  white-space: nowrap;
}

.thead-dark,
.table-dark {
  a {
    color: white;
  }
}

.custom-comms-background {
  background: #e4ddd5;
  background: #e6d3bb url("/images/natural-paper.png");
  /* transparenttextures.com */
}

.scrollBottom {
  position: fixed;
  display: none;
  bottom: 3rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-image: url(/images/arrow-bottom.png);
  background-color: rgba(255, 255, 255, 0.75);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2rem auto;
  transition: transform 500ms cubic-bezier(0.23, 0.73, 0.42, 1.56);
  &.flip {
    transform: rotate(180deg);
  }
}

*[title] {
  text-decoration: underline currentColor dotted;
}

.bootstrap-select .btn {
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.table-fixed-head          { overflow: auto; height: 100px; }
.table-fixed-head thead { position: sticky; top: 0; z-index: 1; }

// .custom-highlight-visited {
//   a:visited {
//     color: green;
//   }
// }